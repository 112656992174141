#main-footer {
  border-top: 5px solid $teal;
  overflow: hidden;
  background-color: $lightgrey;
  padding-bottom: 20px;

  a {
    color: $darkgrey;
  }

  img {
    margin: 2em 0;

    @include media-breakpoint-up(md) {
      margin: 50px 0 10px;
    }
  }

  .copyright p {
    margin-bottom: 0.25em;
  }

  sup {
    font-size: 0.45rem;
    top: -0.65em;
  }

  #bial-note {
    position: relative;
    padding: 0 55px 0 0;
    height: 10px;
    width: auto;
    display: inline-block;
    margin: 0 0 20px;

    img.bial-logo {
      position: absolute;
      right: 0;
      bottom: -7px;
      width: 50px;
      height: auto;
      margin: 0;
    }
  }

  nav {
    margin-top: 20px;
    margin-bottom: 10px;

    li {
      display: block;
      padding: 0 15px 0 0;
      margin: 0 15px 5px 0;

      @include media-breakpoint-up(md) {
        display: inline;
        border-right: 1px solid $darkgrey;

        &:last-child {
          border: none;
        }
      }

      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .footer-container {
      display: flex;
      flex-direction: column;
      align-items: end;
      position: relative;
    }

    .footer-nav,
    .copyright {
      width: calc(100% - 180px);
    }

    .footer-nav {
      font-size: rem-calc(14);
    }

    #n-logo-exit-trigger {
      position: absolute;
      left: 0;
    }

    .copyright {
      font-size: rem-calc(12);
    }
  }
}

// body.pc footer#main-footer {
//   // Apex New font renders diff between mac / pc
//   #bial-note img.bial-logo {
//     bottom: 0;
//   }
// }
