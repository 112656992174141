#videos {
  .video-container {
    padding: 20px 0;

    .vid-text {
      .subheader {
        font-size: 22px;
        color: #05498c;
        @include media-breakpoint-down(md) {
          font-size: 14px;
        }
      }
      .description {
        padding-bottom: 20px;
        max-width: 500px;
      }
    }

    img {
      width: 100%;
      margin-bottom: 10px;

      &:hover {
        cursor: pointer;
      }
    }

    &.active {
      opacity: 50%;
      pointer-events: none;
    }

    &.video-player {
      display: none;
      opacity: 0;
      &.active {
        display: flex;
        opacity: 1;
        pointer-events: all;
        @include media-breakpoint-down(md) {
          flex-direction: column-reverse;
        }
        iframe {
          width: 100%;
          height: 382px;
          max-width: 670px;
          @include media-breakpoint-down(md) {
            height: calc(100vw * 0.5);
          }
        }
      }
    }
  }

  .video-opened .video-container {
    @include media-breakpoint-down(sm) {
      .col-md-7 {
        flex: 0 0 55%;
        max-width: 55%;
      }
      .col-md-5 {
        flex: 0 0 45%;
        max-width: 45%;
      }
      .description {
        display: none;
        &.main {
          display: block;
        }
      }
      .link {
        display: none;
      }
      .subheader {
        margin: 0;
      }
    }
  }
}
