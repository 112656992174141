$floatingisiTransition: max-height 300ms linear;

// ISI
.isi-el {
  .isi-toggle-container {
    display: none;
  }

  a {
    color: $primaryColor;
    font-family: $font-medium;
  }
}

.isi {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  transition-property: height;

  ///////////////////////////
  // Default Floating ISI //
  ///////////////////////////
  &.floating-isi-clone {
    background-color: #fff;
    line-height: 24px;
    max-height: 600px;

    position: fixed;
    bottom: 0;
    left: 0px;
    z-index: 3;
    width: 100%;
    overflow: hidden;
    transition: $floatingisiTransition;

    .isi-toggle-container {
      display: block;
      height: 0;
      position: relative;

      .row {
        position: absolute;
        right: 20px;
        top: 5px;

        .isi__toggle-button {
          background-color: transparent;
          cursor: pointer;
          z-index: 99;
          img {
            width: 20px;
            height: 20px;
          }

          p {
            margin: 0;
            font-family: "ApexNew-medium";
            font-size: rem-calc(10);
            color: $darkgrey;
          }
        }
      }
    }

    .floating-isi-bg-off-white {
      background-color: $lightgrey;
    }

    .hidden-collapsed-isi {
      display: none;
    }

    .floating-isi-row,
    .floating-isi-row-expand,
    .floating-isi-row-close {
      overflow: hidden;
    }

    .floating-isi-row-close {
      transition: $floatingisiTransition;
    }

    .floating-isi-row-expand {
      max-height: 0;
    }
  } // END Default Floating ISI

  ///////////////////////
  // Open Floating ISI //
  ///////////////////////
  &.floating-isi-expanded {
    max-height: calc(100vh - 121px);

    .close-button {
      display: block;
    }
    .expand-button {
      display: none;
    }
    .isi-container {
      position: relative;
      overflow-y: scroll;
      height: calc(100vh - 200px);
    }
    .hidden-collapsed-isi,
    .floating-expand-show {
      display: block;
    }
    .floating-isi-row-expand {
      max-height: 99999px;
    }
  } // END Open Floating ISI

  ////////////////////////
  // Close Floating ISI //
  ////////////////////////
  ///
  .close-button {
    display: none;
  }

  &.floating-isi-close .floating-isi-row-expand {
    max-height: 0;
  } // END Close Floating ISI
}

@include media-breakpoint-down(sm) {
  .isi.floating-isi-clone {
    z-index: 5;
  }
  .isi.floating-isi-expanded {
    max-height: calc(100vh - 121px);
  }
}

.isi-container {
  font-size: rem-calc(18);

  p {
    line-height: 1.3;
    margin-bottom: 1em;
  }

  .isi-header {
    color: $blue;
    font-family: $font-bold;
    margin-bottom: 0;
  }

  .isi-header-p {
    padding-bottom: 0.5em;
  }

  .isi-header-p-2 {
    padding-top: 0.5em;
  }

  ul.bullet {
    padding: 0;

    li {
      margin: 0 0 0 12px;
      padding: 0;
      list-style: none;
      line-height: 1.4;
    }

    li::after {
      content: "•";
      background: none;
      padding-right: 5px;
      margin-left: -9px;
      position: absolute;
      margin-top: 1px;
      font-size: 15px;
      color: rgb(76, 76, 76);
      top: 1px;
    }
  }
}
