@font-face {
  font-family: "ApexNew-thin";
  font-style: normal;
  font-weight: 100;
  src:
    local("ApexNew-Thin"),
    url("../fonts/ApexNew-Thin.woff") format("woff");
}

@font-face {
  font-family: "ApexNew";
  font-style: normal;
  font-weight: 400;
  src:
    local("ApexNew-Book"),
    url("../fonts/ApexNew-Book.woff") format("woff");
}

@font-face {
  font-family: "ApexNew-medium";
  font-style: normal;
  font-weight: 600;
  src:
    local("ApexNew-Medium"),
    url("../fonts/ApexNew-Medium.woff") format("woff");
}

@font-face {
  font-family: "ApexNew-bold";
  font-style: normal;
  font-weight: 700;
  src:
    local("ApexNew-Bold"),
    url("../fonts/ApexNew-Bold.woff") format("woff");
}
