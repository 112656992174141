button {
  border: 0;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0;
  background: $grey-8;
  color: $body-color;
  border: none;
  text-align: left;
  text-transform: uppercase;
  font-size: rem-calc(12);
  font-family: $font-book;
  padding: 0 16px;
  min-width: 170px;
  height: 48px;
  white-space: normal;
  transition: all 0.2s linear;
  cursor: pointer;

  &-small {
    max-width: 250px;
  }
}

.btn-gradient-radius {
  height: 43px;
  min-width: 235px;
  background-image: $gradient-teal-blue;
  background-size: 250%;
  background-position: 50% 0%;
  color: $white;
  border-radius: 15px 0;
  white-space: nowrap;
  transition: all 0.2s linear;
  display: flex;
  position: relative;
  padding-left: 1.5em;

  &::after {
    content: "";
    background: url("../images/arrow-right-small-white.svg") no-repeat;
    background-size: contain;
    width: rem-calc(12);
    height: rem-calc(12);
    position: absolute;
    top: rem-calc(20);
    right: rem-calc(20);
  }

  @include media-breakpoint-up(md) {
    height: 60px;

    &:after {
      top: rem-calc(25);
    }
  }

  span {
    padding-top: rem-calc(5);
    font-size: rem-calc(18);
    font-family: $font-bold;
  }

  &:hover {
    background-position: 0% 0%;
  }
}
.btn-oval {
  background-color: $primaryColorDrk;
  color: $white;
  font-family: $font-medium;
  font-size: rem-calc(18);
  border-radius: 50px;
  height: 50px;
  position: relative;
  padding: 4px 25px 0 40px;
  &::before {
    content: "+";
    font-size: rem-calc(30);
    font-family: Arial, Helvetica, sans-serif;
    position: absolute;
    left: 14px;
    top: 6px;
  }
  &:hover {
    background-color: $grey-8;
    color: $primaryColorDrk;
  }
}

.btn:hover {
  background-color: $grey-8;
}

.icon-plus,
.icon-minus,
.icon-arrow {
  position: relative;
  padding: 5px 40px 0 18px;
  min-width: 160px;
  .symbol {
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    font-size: 20px;
    margin-top: -5px;
    height: 10px;
    width: 10px;
    line-height: 15px;
    text-align: center;
    font-family: $font-medium;
  }
  .symbol-minus {
    display: none;
  }
}

.icon-minus {
  background: $grey-8;
  font-family: $font-bold;
  .symbol.minus {
    display: block;
    font-size: 24px;
  }
  .symbol {
    display: none;
  }
}

.icon-arrow .symbol {
  width: 17px;
  height: 10px;
  background: transparent url("../images/btn-arrow.svg") no-repeat 0 0;
}

.btn-new {
  padding-top: 5px;
  font-size: rem-calc(18);
  font-family: $font-bold;
  &::after {
    top: 24px;
    @include media-breakpoint-down(md) {
      top: 17px;
    }
  }
}

main#error404 {
  .btn-tape.btn-teal {
    width: 256px;
    height: 64px;
    padding: 8px 33px 0 0;
  }
}

main#contact-a-rep,
main#stay-informed {
  .btn-tape.btn-teal {
    width: 251px;
    height: 50px;
    span {
      position: relative;
      top: -11px;
    }
  }
}

main#stay-informed {
  .btn-tape.btn-teal > span {
    top: -14px;
  }
}
