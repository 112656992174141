$notificationHeight: 32px;
$subnavOffset: $scrollMainHeaderH + $notificationHeight;
$notificationMobileNavH: $mobileNavHeight + $notificationHeight;
$notificationMainHeaderH: $mainHeaderH + $notificationHeight;

.show .top-notifications-banner {
  display: flex;
}

.top-notifications-banner {
  padding: 10px;
  background: $darkgrey;
  height: 40px;
  color: $white;
  font-size: rem-calc(12);
  line-height: 1.3;
  display: none;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-down(sm) {
    &.scrolled {
      bottom: 0;
    }
  }

  span {
    position: relative;
  }

  a {
    color: $white;
    text-decoration: underline;
  }

  #close-cookie {
    display: inline-block;
    position: relative;
    left: 10px;
    top: -3px;

    height: 15px;
    width: 15px;
    background: 0;
    cursor: pointer;

    &::after,
    &::before {
      position: absolute;
      right: 8px;
      top: 0;
      content: " ";
      height: 15px;
      width: 1px;
      background-color: $primaryColorDrk;
      cursor: pointer;
      background-color: #fff;
    }

    &::after {
      transform: rotate(45deg);
    }
    &::before {
      transform: rotate(-45deg);
    }
  }
}
