#safety {
  .table-container {
    tbody tr td:nth-of-type(2) {
      width: 350px;
    }
    tbody tr td:nth-of-type(3) {
      width: 350px;
    }
    tbody tr td:nth-of-type(1) {
      padding-left: 20px;
    }

    @include media-breakpoint-down(sm) {
      tbody,
      thead {
        font-size: 13px;
      }
      th {
        line-height: 16px;
      }
      tbody tr td:nth-of-type(3) {
        width: 140px;
      }
      tbody tr td:nth-of-type(2) {
        width: 140px;
      }
      tbody tr td:nth-of-type(1) {
        padding-left: 7px;
      }
      thead tr th:nth-of-type(1) {
        padding-left: 7px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .table-container {
      padding: 0 0;
    }
  }
  thead tr {
    border-top: 1px solid rgba(128, 128, 128, 0.439);
  }
}
