ul.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
  li::after {
    display: none;
  }
}

.icon-list {
  li {
    padding: 20px 0;
    margin: 0;
    border-bottom: 1px solid $lightgrey;
    &:last-of-type {
      border-bottom: none;
    }
  }

  .icon-container {
    @include media-breakpoint-down(sm) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 100%;
      max-width: 99px;
    }
  }

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      margin: 0;
      font-size: rem-calc(20);
    }
  }
}

ul.bullet li {
  position: relative;
  list-style: none;
  margin: 10px 0;
  padding: 0 0 0 0.75rem;
  border: none;

  ul {
    margin: 10px 0;
  }

  li::after {
    position: absolute;
    color: $teal;
    content: "–";
    left: 0;
    top: 0px;
  }
}

ul.bullet > li::after {
  content: "";
  position: absolute;
  top: 8px;
  left: 0;
  height: 5px;
  width: 5px;
  background-color: $teal;
}

ol {
  padding: 0;
  counter-reset: section;

  li {
    list-style: none;
    margin: 0 0 0 40px;
    padding: 0 0 0 37px;
    position: relative;

    &::before {
      counter-increment: section;
      content: counters(section, ".") ". ";
      font-variant-numeric: lining-nums;
      position: absolute;
      left: 2rem;
    }
  }
}
