#dosing {
  .teal-numbers {
    li {
      margin: 0 0 10px -2rem;
      padding-left: 3rem;

      &::before {
        color: $teal;
        font-family: $font-bold;
      }
    }
  }

  #once-daily {
    @include media-breakpoint-down(sm) {
      h3 {
        text-align: center;
        margin: 8px auto 8px;
      }
    }
  }
}
