.accessibility-statement {
  position: absolute;
  left: -500px;
  z-index: 200;
  width: 200px;
  background: white;
  padding: 5px;
  &:focus {
    left: 10px;
  }
}

:focus-visible {
  border: 2px solid black;
}

header#main-nav {
  background-color: rgba(255, 255, 255, 0.95);
  width: 100%;
  z-index: 4; // Modals set to 5
  position: sticky;
  top: 0;
  box-shadow: none;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(sm) {
    #secondary-nav {
      grid-area: secondary;
    }
    #primary-nav {
      grid-area: primary;
    }
    #page-banner {
      grid-area: banner;
    }

    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
    display: grid;
    grid-template-areas:
      "primary"
      "secondary"
      "banner";
  }

  #primary-nav,
  #secondary-nav,
  .intention-p,
  h1,
  .h1,
  nav a,
  section {
    transition: height 0.5s ease;
  }

  #primary-nav,
  #secondary-nav {
    .row {
      height: 100%;
    }
  }

  .nav-content {
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: row;
    }

    @include media-breakpoint-down(sm) {
      .intention-p {
        font-size: 10px;
        flex: 2;
        margin-left: 1rem;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  #main-nav {
    position: relative;
  }
}

@include media-breakpoint-down(md) {
  #primary-nav nav > ul {
    height: calc(100vh - 60px);
  }
}

#primary-nav {
  background: $white;

  .container {
    height: $mainNavH;
  }

  @include media-breakpoint-up(md) {
    position: relative;
    height: $mainNavH;
  }

  .logo {
    margin: 10px 0 0;
    padding: 6px 0 0 0;
    a {
      display: inline-block;
    }

    @include media-breakpoint-up(md) {
      margin: 0 10px 0 0;
      padding: 0;
      display: flex;
      align-items: stretch;
    }

    @include media-breakpoint-up(lg) {
      margin-right: 40px;
    }
  }

  img {
    width: 134px;
    height: 33px;
    @include media-breakpoint-up(md) {
      height: 40px;
      width: 163px;
    }
    @include media-breakpoint-up(lg) {
      height: 59px;
      width: 240px;
    }
  }

  nav {
    overflow: hidden;
    @include media-breakpoint-up(md) {
      flex-grow: 1;
      position: relative;
      height: 60px;
    }
  }

  nav > ul {
    position: absolute;
    top: 60px;
    left: 101%;
    right: 0;
    background-color: #fff;
    overflow: hidden;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    box-shadow: 0 8px 7px -3px rgba(0, 0, 0, 0.24);
    line-height: 1.3em;
    transition: all 0.5s ease-in-out;
    z-index: 100;
    @include media-breakpoint-up(md) {
      transition: none;
      display: flex;
      height: 100%;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      flex-grow: 1; // set all links to equal widths
      position: relative;
      top: auto;
      left: auto;
      background-color: transparent;
      box-shadow: none;
      li {
        display: flex;
        height: 100%;
        width: 100%; // equal widths
      }
    }

    li,
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      @include media-breakpoint-down(sm) {
        min-width: 200px;
        margin: 0 20px;
      }

      &::after {
        display: none;
      }
    }
  }

  nav.active ul {
    left: 0;
    overflow: scroll;
  }

  nav a {
    display: flex;
    width: 100%; // equal widths
    align-items: center;
    color: $body-color;
    font-weight: 600;
    font-family: "ApexNew-medium", Helvetica, Arial, sans-serif;
    padding: 14px 0px 10px;
    text-align: left;
    transition: color 0.3s;
    font-size: rem-calc(16);
    justify-content: flex-start;
    @include media-breakpoint-up(md) {
      min-height: 0;
      text-align: center;
      padding: 5px;
      // border-top: none;
      letter-spacing: rem-calc(0.5);
      justify-content: center;
      font-size: rem-calc(14);
      line-height: 1.3;

      &:last-child {
        padding: 0;
      }
    }

    &.active {
      color: $primaryColorDrk;
    }

    @include media-breakpoint-up(md) {
      span {
        display: inline-flex;
        align-items: center;
        position: relative;
        height: 100%;
        min-height: 60px;
        border-top: 5px solid transparent;
      }

      &.active span {
        border-color: $blue;
        font-family: $font-bold;
      }
    }

    @include media-breakpoint-up(lg) {
      font-size: 1rem;
    }

    @include media-breakpoint-up(md) {
      &:focus,
      &:hover {
        color: $primaryColorDrk;
      }
    }
  }

  // MOBILE STYLES
  @include media-breakpoint-down(sm) {
    nav li {
      border-bottom: 1px solid $lightgrey;

      &.li-active {
        border-bottom: 2px solid $primaryColorDrk;
      }

      &.border-b-none,
      &:last-of-type {
        border-bottom: none;
      }

      a {
        position: relative;
      }

      .dropdown {
        cursor: pointer;
        content: "";
        outline: none;
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
        height: 100%;
        background: transparent url("../images/arrow-down-small-grey.svg") no-repeat center right 15px;
        background-size: 14px 9px;
        transition: all 0.3s;
      }

      &.expanded {
        ul.sublinks {
          display: block;
        }

        .dropdown {
          transform: scaleY(-1);
        }
      }
    }

    nav li.unstyled {
      background: #f5f5f5;
      border: none;
      margin: 0;
      padding: 0 20px;

      a {
        font-family: $font-book;
        color: $body-color;
        font-size: rem-calc(14);
        border-bottom: 1px solid $lightgrey;
        &::after {
          content: "";
          display: block;
          width: 44.8px;
          height: 100%;
          background: transparent url("../images/arrow-right-small-grey.svg") no-repeat center right 15px;
          background-size: 9px 14px;
          position: absolute;
          right: 0;
          top: 0;
        }
        &.share-icon::after {
          background: transparent url("../images/open-external-link.svg") no-repeat center right 15px;
          background-size: 14px 14px;
        }
      }

      &:last-of-type a {
        border-bottom: none;
      }
    }

    ul.sublinks {
      margin: 0 0 8px 0;

      li {
        margin: 0;
        border: none;
      }

      a {
        font-family: $font-book;
        color: $body-color;
        padding-top: 3px;
        padding-bottom: 3px;
        font-size: rem-calc(14);
        text-transform: uppercase;
      }

      .active {
        color: $primaryColorDrk;
      }
    }
  }

  .sublinks {
    display: none;
  }
}
.is-mobile #primary-nav nav > ul {
  position: fixed;
}
// end #primary-nav

#secondary-nav {
  background-color: $lightgrey;
  font-size: 0.8em;
  line-height: 0.8em;

  .intention-p,
  nav {
    margin: 0;
    display: flex;
    align-items: center;
    height: $scrollSubNavH;
  }

  nav div {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 1rem;

    @include media-breakpoint-up(md) {
      padding: 0 1.75rem;

      &:nth-of-type(3) {
        padding-right: 3.5rem;
      }
    }

    a {
      color: $darkgrey;
      font-size: rem-calc(11);
      letter-spacing: rem-calc(0.2);
      line-height: rem-calc(11);

      &.active {
        text-decoration: underline;
        color: $teal;
      }
    }

    &.btn-gradient {
      background-image: $gradient-teal-blue;
      background-size: 250%;
      background-position: 50% 0%;
      padding: 0 20px;
      transition: all 0.2s linear;

      a {
        color: $white !important;
        font-family: $font-medium;
      }

      &:hover {
        background-position: 0% 0%;
      }
    }
  }
}

#navbar-toggler {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 20px;
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  &:focus-visible {
    border: 2px solid black;
  }
  span {
    display: block;
    height: 2px;
    width: 100%;
    background-color: $darkgrey;
    transition: 0.3s;

    &:nth-child(2) {
      margin: 7px 0;
    }
  }

  &.active {
    span:nth-child(1) {
      transform: rotate(45deg);
      margin-top: 0;
    }

    span:nth-child(2) {
      opacity: 0;
    }

    span:nth-child(3) {
      transform: rotate(-45deg);
      margin-top: -18px;
    }
  }
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.is-mobile #navbar-toggle {
  position: fixed;
}

@include media-breakpoint-up(md) {
  body,
  body.scrolled,
  body.redirect {
    header#main-nav {
      &.box-shadow {
        box-shadow: 0 8px 7px -3px rgba(0, 0, 0, 0.24);
      }
    }

    #primary-nav {
      height: $scrollMainNavH;

      img {
        height: 40px;
        width: 163px;
      }

      nav a {
        font-size: rem-calc(14);
        line-height: 1.3;
      }
    }
  }
}

/* Subnav */
#subnav {
  background: $blue;

  &.sticky {
    position: fixed;
    top: $scrollMainHeaderH;
    width: 100%;
    margin-top: 0;
    padding-top: 0;
    z-index: 2;
    box-shadow: 0 8px 7px -3px rgba(0, 0, 0, 0.24);
  }

  @include media-breakpoint-down(md) {
    & > .container {
      padding: 0;
    }
  }

  .nav {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    align-items: stretch;
    justify-content: space-around;
    min-height: $subnavH;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: center;
    }

    @include media-breakpoint-up(md) {
      justify-content: center;
      height: $subnavH;
    }

    a {
      color: $white-opaque;
      font-family: $font-medium;
      display: block;
      text-align: center;
      padding: 6px 4px 2px;
      border-bottom: 1px solid $grey-1;
      font-size: rem-calc(14);

      &:last-child {
        border: none;
      }
      @include media-breakpoint-up(sm) {
        padding: 0;
        border-bottom: 2px solid transparent;
        text-transform: uppercase;

        &.active {
          border-bottom: 2px solid $white;
        }
      }

      @include media-breakpoint-up(md) {
        font-size: rem-calc(12);
        margin: 0 40px;
      }

      @media (min-width: 992px) {
        font-size: rem-calc(14.5);
      }
      &.active {
        color: $white;
      }
    }
  }
}

/* END TEST sticky mobile subnav accordion */
#menu-line {
  position: absolute;
  top: 0;
  left: 21px;
  height: 5px;
  width: 60px;
  opacity: 0;
  pointer-events: none;
  background: #05498c;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transition-timing-function: cubic-bezier(1, 0.01, 0, 1);
  -webkit-transition-timing-function: cubic-bezier(1, 0.01, 0, 1.22);
  transition-timing-function: cubic-bezier(1, 0.01, 0, 1.22);
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

///////////////////
// MOBILE SUBNAV //
///////////////////
#page-banner {
  .subnav-m {
    display: none;
  }

  .nav-page-name {
    display: none;
  }

  @include media-breakpoint-down(sm) {
    #subnav {
      height: 0;
      overflow: hidden;

      .nav-page-name {
        margin: 0;
        padding: 10px;
        color: $white;
        font-family: "ApexNew-bold";
        font-size: 28px;
        text-transform: uppercase;
      }
      .nav a {
        text-align: left;
        text-transform: uppercase;
        border-bottom: 1px solid #b7b7b7;
        font-size: 18px;
        padding: 10px 0;
        margin: 0 10px;
        position: relative;

        &::after {
          content: "";
          width: 11px;
          height: 7px;
          background-image: url("../images/icon-white-arrow-down.png");
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          right: 0px;
          top: 20px;
          transform: rotate(-90deg);
        }
      }
    }

    .subnav-m {
      display: block;
      background-color: $blue;
      p {
        color: $white;
        font-family: "ApexNew-medium", Helvetica, Arial, sans-serif;
        padding: 10px;
        margin: 0;
        position: relative;
        text-transform: uppercase;
        &::after {
          content: "";
          width: 11px;
          height: 7px;
          background-image: url("../images/icon-white-arrow-down.png");
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          right: 20px;
          top: 20px;
        }
      }
    }

    &.sub-expanded {
      .nav-page-name {
        display: block;
      }
      #subnav {
        height: calc(100vh - 90px);
      }
      .subnav-m {
        p::after {
          transform: rotate(180deg);
        }
      }
    }
  }
}
