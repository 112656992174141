.tab-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 34px 0 38px;
}

.tab {
  position: relative;
  border: none;
  width: 226px;
  height: 50px;
  color: $body-color;
  text-transform: uppercase;
  border-radius: 0;
  border: 0;
  background: $grey-8;
  font-size: rem-calc(12);
  margin-right: 1px;
  padding-top: 4px;
  cursor: pointer;

  &.active {
    outline: none;
    background: $grey-8;
    font-family: $font-bold;
    &::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 50%;
      height: 3px;
      background: $primaryColor;
      width: 50%;
      transform: translate(-50%, 0);

      @include media-breakpoint-up(md) {
        width: 85px;
      }
    }
  }
}
.bg-off-white .tab {
  background: #efeeeb;
  &.active {
    background: $grey-8;
  }
}

.tab-content {
  display: none;
  &.active {
    display: block;
  }
}
