main#why-ongentys {
  .text-container {
    max-width: 850px;
    margin: 4em auto 4em;

    &--left {
      position: relative;

      span {
        font-size: rem-calc(112);
        font-family: $font-bold;
        color: $primaryColorDrk;
      }

      p {
        bottom: 0;
        position: absolute;
        font-size: rem-calc(18);
      }
    }

    &--right {
      font-size: rem-calc(20);
    }
  }
}
