#acc-overview-study-4 {
  .accordian-body-container {
    .table-container {
      tbody tr td:nth-of-type(n + 2) {
        width: 170px;
      }

      thead:nth-of-type(2) {
        border-bottom: none;
      }

      .table-1 {
        thead:nth-of-type(1) {
          border-bottom: none;
          height: 45px;

          tr {
            th {
              border-bottom: 1px solid $darkblue;
            }

            th:nth-of-type(2) {
              color: $darkblue;
            }

            th:nth-of-type(3) {
              color: $teal;
              background: none;
            }
          }
        }
      }
    }

    .table-border {
      td,
      th {
        border-right: 1px solid $darkblue;
      }
    }

    .mobile {
      padding: 0px;
      th {
        padding: 5px 5px;
        line-height: 16px;
      }
      td {
        vertical-align: middle;
        padding: 6px 5px;
      }
      thead {
        font-size: 13px;
      }
      tbody {
        font-size: 13px;
      }
      tbody tr td:nth-of-type(3) {
        width: 120px;
      }
      tbody tr td:nth-of-type(2) {
        width: 120px;
      }
      tbody tr td:nth-of-type(1) {
        // width: 240px;
        padding-left: 7px;
      }
      thead tr th:nth-of-type(1) {
        padding-left: 7px;
      }
      .header-table {
        border-top: 1px solid rgba(128, 128, 128, 0.52);
      }
      .bipark-1 {
        border-bottom: none;
        tr {
          th {
            border-radius: 30px;
            background: #1a2050;
            color: #ffffff;
            text-align: center;
            padding-bottom: 4px;
            border-bottom: 0;
          }
        }
      }
      .bipark-2 {
        border-bottom: none;
        tr {
          th {
            border-radius: 30px;
            background: #008484;
            color: #ffffff;
            text-align: center;
            padding-bottom: 4px;
          }
        }
      }
    }
  }
}

.table-container {
  margin: 0 auto;
  max-width: 1224px;
  padding: 1em;
  overflow: auto;
  background-color: #ffffff;
  @extend .chart-container; 
}
.table-container table {
  text-align: center;

  th:nth-of-type(1) {
    vertical-align: bottom;
  }

  th:nth-of-type(2),
  th:nth-of-type(4) {
    color: #8a8380;
    text-align: center;
  }
  th:nth-of-type(3),
  th:nth-of-type(5) {
    color: #ffffff;
    background: linear-gradient(to right, #1a2050, #124b8a);
    text-align: center;
  }

  thead tr th:nth-of-type(1) {
    text-align: left;
    border-left: none;
    padding-left: 1em;
  }

  tbody {
    font-family: ApexNew-Medium;
  }

  tbody tr td:nth-of-type(1) {
    text-align: left;
    border-left: none;
    color: #1a2050;
    padding-left: 1em;
  }

  tbody tr td:nth-of-type(2) {
    text-align: left;
    color: #8a8380;
    text-align: center;
    margin-right: 10px;
  }

  tbody tr td:nth-of-type(3) {
    background: linear-gradient(to right, #1a2050, #124b8a);
    color: #ffffff;
    width: 180px;
  }

  tbody tr td:nth-of-type(5) {
    background: linear-gradient(to right, #1a2050, #124b8a);
    color: #ffffff;
  }
}

.border-bottom-blue {
  border-bottom: 1px solid $darkblue;
}

.border-bottom-white {
  border-bottom: 1px solid $lightgrey;
}

.bg-lightblue {
  background-color: #d6ecec;
}

table {
  border-collapse: collapse;
  color: #8a8380; //#4a4a4d;
  font-family: "ApexNew";
  width: 100%;
}

thead {
  color: #1a2050;
  font-family: ApexNew-Medium;
}

tbody tr:nth-child(even) {
  background: #f0f0f2c8;
}

th,
td {
  padding: rem-calc(8);
  vertical-align: middle;
}

td {
  border-bottom: 1px solid $lightgrey;
}

td:first-child {
  border-left: 1px solid $lightgrey;
}
