body.clinical-data {
  @include media-breakpoint-up(md) {
    #subnav .nav {
      justify-content: space-between;
      a {
        margin: 0 20px;
      }
    }
  }
}

main#clinical-data {
  #earlier-subgroups {
    p {
      max-width: 786px;
    }

    .chart-container {
      @include media-breakpoint-down(sm) {
        background-color: transparent;
        border-bottom: 1px solid $lightgrey;
      }
    }
  }

  #graph-early-levo-container {
    h3 {
      text-align: center;
    }
    .chart {
      padding-bottom: 20px;
    }
  }

  .pie-chart {
    img {
      display: block;
      margin: 30px auto;
      max-width: 340px;
      width: 100%;
    }
  }
}
