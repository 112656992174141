// Template
#page-content {
  min-height: 100%;
  overflow-x: hidden;
}

#skip-nav {
  display: inline-block;
  position: absolute;
  left: -500px;
  overflow: hidden;
  opacity: 0;
  text-decoration: underline;
  top: 0;

  &:focus {
    left: 10px;
    color: #fff;
    background-color: $blue;
    padding: 10px 20px;
    overflow: auto;
    opacity: 1;
    z-index: 1000;
  }
}

.accessible-chart-modal {
  display: block;
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  text-decoration: underline;

  &:focus {
    color: #fff;
    background-color: $blue;
    padding: 5px 10px;
    overflow: auto;
    width: auto;
    height: auto;
    opacity: 1;
    z-index: 1000;
  }
}

article {
  margin: 25px 0;

  .bar-top {
    border-top: 5px solid $primaryColorDrk;
  }

  .article-title {
    padding: rem-calc(20) 0 rem-calc(5);
    margin: 0;
    font-family: $font-bold;
    text-transform: uppercase;
  }

  .article-header {
    text-transform: uppercase;
    margin-bottom: rem-calc(40);
    line-height: 1.2;
  }
} // END Article Background colors
////////////////////////////////////////////

// text template container
.text-template-container {
  .pre-title {
    font-family: $font-bold;
    color: $primaryColorDrk;
    margin: 0;
  }
  .title {
    font-size: rem-calc(24);
    color: $primaryColorDrk;
    text-transform: none;
    letter-spacing: normal;
    margin-bottom: 5px;
  }
  .description {
    max-width: 350px;
  }
} // END text template container
////////////////////////////////////////////

// abbreviated
.abbreviated {
  margin-top: 2em;
  margin-bottom: 4em;

  p {
    margin-bottom: 0.25em;
    font-size: rem-calc(14);
  }
} // END abbreviated
////////////////////////////////////////////

// teal box
.teal-box-container {
  margin-top: 20px;

  .teal-box {
    padding: 35px;
    background: $gradient-blue-teal;

    @include media-breakpoint-down(sm) {
      padding: 30px 25px;
    }

    p {
      color: $white;
      text-align: center;
      font-family: $font-medium;
      font-size: rem-calc(26);
      line-height: 1.3;
      margin: 0 auto;

      sup {
        &.dagger {
          font-size: 18px;
          top: -0.45em;
        }

        &.font-arial {
          top: -1.1em;
          font-size: 11px;
        }
      }
    }

    a {
      color: $white;
      text-decoration: underline;
    }

    &.long p {
      max-width: 700px;
    }
  }
} // END teal box
////////////////////////////////////////////

// to-next-page
.to-next-page {
  padding: 3em 0 2em;

  .title-green {
    font-family: $font-bold;
    text-transform: uppercase;
    text-align: center;
    color: $teal;
    font-size: rem-calc(38);
    max-width: 700px;
    margin-bottom: 0.5em;
    line-height: 1.2;
  }
} // END to-next-page
////////////////////////////////////////////

// Accordian
.accordian-container {
  margin-bottom: 50px;

  .accordian-title-container {
    display: flex;

    .btn-accordian {
      background-color: transparent;
      background-image: url("../images/plus-blue.png");
      background-size: contain;
      min-width: auto;
      height: 1.5rem;
      width: 1.5rem;
      padding: 0;
      transform: rotate(90deg);
      transition: all 0.3s ease-in-out;
    }
    .accordian-btn-name {
      font-size: rem-calc(22);
      font-family: $font-medium;
      color: $primaryColorDrk;
      padding-left: 1.2rem;
      text-transform: uppercase;
    }
  }
  .accordian-body-container {
    display: none;
    max-height: 0;
    transition: all 0.3s ease-in-out;
  }

  .accordian-title {
    margin-top: 20px;
    text-transform: uppercase;
    font-family: $font-bold;
    font-size: rem-calc(22);
    &.font-normal {
      text-transform: none;
    }
  }

  &.expand {
    .accordian-body-container {
      display: block;
    }
    &.active {
      .btn-accordian {
        background-image: url("../images/minus-blue.png");
        background-size: contain;
        transform: rotate(0deg);
      }
      .accordian-body-container {
        max-height: 5000px;
      }
    }
  }
} // END Accordian
////////////////////////////////////////////

.chart-container {
  background-color: $grey-8;
  margin: 20px 0 rem-calc(60);
  padding: 5px;

  @include media-breakpoint-up(md) {
    padding: 20px;
  }

  img {
    width: 100%;
  }
}

// Page Banners

#cards-container.container-fluid {
  @include media-breakpoint-up(md) {
    padding-right: 40px;
    padding-left: 40px;
  }
  .cards-row > div {
    margin-bottom: 15px;
  }
}

.card {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  color: $darkgrey;
  border: none;
  border-radius: 0;
  line-height: 1.2;
  flex-grow: 1;
  background: none;
  box-shadow: 0px 5px 13px 0px rgba(172, 172, 172, 0.57);
  height: 100%;
  @include media-breakpoint-up(lg) {
    margin: 20px 29px 0 0;
  }

  .card-text {
    position: relative;
    padding: 30px 27px 0;
    transition: all 0.3s ease-in-out;
    h3 {
      margin-bottom: 17px;
      font-size: rem-calc(18);
      color: $teal;
      font-family: $font-book;
    }
    p {
      line-height: 1.6;
      font-size: rem-calc(14);
    }
  }

  .card-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 27px;
    height: 49px;
    margin: 0;
    font-size: rem-calc(12);
    letter-spacing: 1px;
    text-transform: uppercase;
    background: #f9f9f9;
    color: $body-color;
    &:hover {
      color: $primaryColor;
    }
    &.download {
      background-image: url("../images/download-icon-teal.png");
      background-repeat: no-repeat;
      background-position: right 24px center;
      background-size: 16px 21px;
    }
    &.play {
      background-image: url("../images/icon-play.png");
      background-repeat: no-repeat;
      background-position: right 24px center;
    }
  }
}
