@import "bootstrap/bootstrap";
@import "vars";
@import "mixins";
@import "fonts";
@import "global";
@import "display";
@import "type";
@import "spacing";
@import "buttons";
@import "tabs";
@import "modals";
@import "forms";
@import "template";
@import "notifications";
@import "footer";
@import "isi";
@import "list";
@import "references";
@import "pages/home";
@import "pages/impact-of-comt";
@import "pages/why-ongentys";
@import "pages/clinical-data.scss";
@import "pages/safety.scss";
@import "pages/dosing.scss";
@import "pages/resources.scss";
@import "pages/sitemap.scss";
@import "pages/support.scss";
@import "pages/videos";
@import "table.scss";

html,
body {
  background-color: #fff;
  color: $body-color;
  font-family: $font-book;
  letter-spacing: 0.01rem;
  margin: 0;
  padding: 0;
  font-variant-numeric: lining-nums;
  font-size: 14px;

  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
}
button,
input,
optgroup,
select,
textarea {
  font-family: $font-book;
  font-variant-numeric: lining-nums;
}
main {
  position: relative;
  z-index: 1;
}
a {
  color: $teal;
}
a:focus,
a:hover {
  // color: inherit;
  text-decoration: none;
}

@import "navigation";

// Responsive
@include media-breakpoint-down(md) {
  .lrg-desktop {
    display: none !important;
  }
}
@include media-breakpoint-down(sm) {
  .desktop {
    display: none !important;
  }
}

@include media-breakpoint-up(md) {
  .mobile {
    display: none !important;
  }
}

// BASE
p,
ul {
  margin-bottom: 10px;
}
ul {
  margin: 0;
  padding: 0;
}

.hidden {
  display: none;
}

//supportible hiding, for alert div, for screenreaders

.font-color-title {
  color: $primaryColorDrk;
}

.bg-off-white {
  background-color: $lightgrey;
}

.border-top-grey {
  border-top: 1px solid $lightgrey;
}

.border-bottom-grey {
  border-bottom: 1px solid $lightgrey;
}
