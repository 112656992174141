.references {
  font-size: rem-calc(12.8);
  margin: 1rem 0;
  line-height: 1.4;
  h2 {
    float: left;
    font-family: $font-bold;
    font-size: rem-calc(12.8);
    line-height: 1.4;
    margin: 0;
    padding: 0;
    color: $darkgrey;
    display: inline-block;
    letter-spacing: 0.5px;
    font-weight: normal;
    text-transform: none;
  }
  ol {
    margin: 0;
    padding: 0 0 0 3px;
    display: inline;
    counter-reset: item;
  }
  ol li {
    margin: 0;
    display: inline;
    padding: 0 3px 0 0;
    position: relative;
    list-style-type: none;
    counter-increment: item;

    &:before {
      position: relative;
      top: inherit;
      left: inherit;
      display: inline-block;
      width: auto;
      text-align: right;
      content: counter(item) ".";
      height: auto;
      background: none;
      padding: 0;
      margin: 0 2px 0 0;
      font-size: rem-calc(12.8);
      line-height: 1.4;
      font-family: $font-bold;
      font-variant-numeric: lining-nums;
    }
  }
  a {
    color: inherit;
    text-decoration: none;
  }
}
