#sitemap {
  ul.list {
    margin: 5px 0 0 20px;
    padding: 0;

    li {
      margin: 0 0 5px;
      padding: 0;

      ul {
        margin-left: 15px;
      }
    }

    a {
      color: $body-color;
      font-size: rem-calc(18);
      @extend .font-medium;
    }

    a:hover,
    a:focus {
      color: $primaryColor;
      text-decoration: underline;
    }
  }

  // Parent ul
  ul.list:not(.nested) {
    padding: 0;

    & > li {
      margin-bottom: 45px;

      & > a {
        font-family: $font-bold;
        font-size: rem-calc(20);
      }
    }
  }
}
