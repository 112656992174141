main#support {
  article .article-subheader {
    margin-top: 0;
  }

  .bullet {
    font-size: rem-calc(20);

    li:after {
      top: 0.45em;
    }
  }

  .icon-list li {
    .icon-container {
      display: flex;
      justify-content: center;
      padding-right: 0;
    }

    .text-container {
      @include media-breakpoint-up(sm) {
        padding-left: 0;
      }
    }
  }

  #inbrace-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    img {
      max-width: 320px;
      width: 100%;
    }
    .abbreviated {
      p {
        max-width: 440px;
        text-align: center;
      }
    }
    .teal-box-container {
      width: 100%;
    }
    .teal-box {
      padding: 30px 120px;
      @include media-breakpoint-down(lg) {
        padding: 30px 60px;
      }
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      @include media-breakpoint-down(sm) {
        padding: 30px 15px;
      }
      p {
        max-width: 560px;
        a {
          word-break: break-word;
        }
      }
    }
  }
}
