// Modals
#exit-modal {
  display: none;
}

[data-modal] {
  background-color: rgba(0, 0, 0, 0.85) !important;
  display: flex;
  align-items: center;

  ul li ul li {
    margin-bottom: 10px;
  }

  aside {
    display: flex;
    flex-direction: column;
  }

  .transcript-container {
    flex-shrink: 1;
    width: 100%;
    max-height: 60%;
    padding: 0;
    margin-bottom: 0;

    .btn-container {
      margin-bottom: 0;
      background-color: #fff;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      p {
        margin-top: 3px;
      }
    }
    .transcript {
      background-color: #fff;
      height: calc(100% - 43px);
      p {
        line-height: 1.2rem;
      }
      &.active {
        overflow: scroll;
      }
    }
  }
}

.is-mobile [data-modal] {
  // height: calc(100vh - 64px) !important; // 64 is height of mobile sticky isi
  aside {
    @include media-breakpoint-down(sm) {
      padding: 25px 0 0 10px;
    }
  }
  > div {
    padding: 20px;
  }
}

body.full-isi [data-modal] {
  height: 100vh !important;
}

$close-button-size: 34px;

[data-modal] > div {
  display: flex;
  position: relative;
  width: 60%;
  max-width: 765px;
  box-shadow: 0px 11px 76.44px 21.56px rgba(0, 0, 0, 0.8);
  max-height: calc(100% - 50px);
  padding: 20px;

  @include media-breakpoint-down(sm) {
    padding: 50px 5px;
  }

  .scroll-container {
    height: auto;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  a {
    color: $body-color;
    text-decoration: underline;
  }
  @include media-breakpoint-down(sm) {
    width: 85%;
  }

  aside {
    padding: 20px;
    margin: 0 auto;
    @include media-breakpoint-down(sm) {
      padding: 0px;
    }
  }

  .modal__close-button {
    position: absolute;
    right: -15px;
    top: -15px;
    width: $close-button-size;
    height: $close-button-size;
    background: $blue;
    border-radius: 50%;
    // box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 3px solid white;
    box-sizing: border-box;

    &:hover {
      background-color: $grey-8;
      &:before,
      &:after {
        opacity: 1;
      }
    }

    &:before,
    &:after {
      position: absolute;
      top: 4px;
      left: 13px;
      content: " ";
      height: $close-button-size - 14;
      width: 2px;
      background-color: white;
      opacity: 1;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  p {
    margin-bottom: 1rem;
  }

  ul {
    margin-bottom: 1rem;
  }

  .modal__highlight--blue {
    color: $primaryColorDrk;
    font-family: $font-bold;
  }

  img {
    margin-top: 1rem;
  }
}

[data-modal="exit-modal"] {
  & > div {
    min-height: 418px;
    max-width: 630px;
  }

  .h1 {
    color: $primaryColor;
    font-size: rem-calc(38);
    text-transform: uppercase;
  }
}

[data-modal="video-modal"],
[data-modal="video-modal-1"],
[data-modal="video-modal-2"],
[data-modal="video-modal-3"] {
  & > div {
    height: auto;
    max-height: 90vh;
    margin-top: 25px;
    background-color: transparent;

    iframe {
      width: 100%;
    }

    .embed-container {
      position: relative;
      height: 0;
      overflow: hidden;
      max-width: 100%;
      height: auto;
    }
    .embed-container iframe,
    .embed-container object,
    .embed-container embed {
      width: 100%;
      aspect-ratio: 29/16;
      max-height: 50vh;
    }

    .aside {
      width: 100%;
      padding: 0;

      .text-center {
        max-height: 50vh;
      }

      div {
        line-height: 0;
      }
    }
  }
}
