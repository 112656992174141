#resources {
  .resource-square {
    margin-bottom: 30px;

    .text-template-container {
      > img {
        width: auto;
        height: 100px;
        margin-bottom: 1rem;
      }

      .title {
        font-family: $font-bold;
      }

      @include media-breakpoint-down(md) {
        border-bottom: 1px solid $lightgrey;
        padding: 20px 0;

        &:last-of-type {
          border-bottom: none;
        }
      }

      @include media-breakpoint-up(md) {
        max-width: 350px;
        border-bottom: none;

        p:not(.title) {
          min-height: 50px;
        }

        .link {
          border-top: 1px solid $lightgrey;
          padding: 10px 0;
        }
      }
    }
  }
}
