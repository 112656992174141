// Mixin to generate spacing utilities
@mixin spacing-utilities {
  @for $i from 1 through 10 {
    $value: rem-calc($i * 10);

    .mt-#{$i} {
      margin-top: $value;
    }
    .mb-#{$i} {
      margin-bottom: $value;
    }
    .ml-#{$i} {
      margin-left: $value;
    }
    .mr-#{$i} {
      margin-right: $value;
    }
  }
}

// Mixin to generate padding utilities
@mixin padding-utilities {
  @for $i from 1 through 6 {
    $value: $i * 1em;

    .pb-#{$i} {
      padding-bottom: $value;
    }
    .pt-#{$i} {
      padding-top: $value;
    }
    .pl-#{$i} {
      padding-left: $value;
    }
    .pr-#{$i} {
      padding-right: $value;
    }
  }
}

// Usage
@include spacing-utilities;
@include padding-utilities;

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}
