h1,
.h1 {
  font-family: $font-bold;
  font-size: rem-calc(38);
  margin: 10px 0 0;
  line-height: 1.3;
  text-transform: uppercase;

  @include media-breakpoint-up(lg) {
    font-size: rem-calc(42);
    line-height: 1.1;
  }

  sup.reg {
    font-size: 40%;
    line-height: 1;
    top: -1.2em;
  }
}

h2,
.h2 {
  font-size: rem-calc(38);
  color: $teal;
  text-transform: uppercase;
  margin-bottom: 15px;
  line-height: 1.19;
  font-family: $font-bold;
}

h3,
.h3 {
  font-family: $font-bold;
  font-size: rem-calc(20);
  color: $primaryColorDrk;
  line-height: 1.3;

  sup.dagger {
    font-size: 13px;
    top: -0.3em;
  }
  sup.reg {
    top: -1em;
  }
}

.accordion h3,
.cards h3,
.h3-alt {
  font-size: rem-calc(18);
  color: $primaryColor;
  font-family: $font-book;
}

h4,
h6,
.h4,
.h6 {
  font-size: 1rem;
  font-family: $font-bold;
  letter-spacing: 0.5px;
}

h5,
.h5 {
  font-family: $font-bold;
  color: $green;
  text-transform: uppercase;
  font-size: rem-calc(20);
}

sub {
  bottom: -0.1em;
}

sup,
.sup {
  font-size: 56.5%;
  top: -0.65em;
  // top: -0.75em;
  font-family: $font-book !important;
  font-style: normal !important;

  &.dagger {
    font-size: 13px;
    top: -0.15em;
  }

  &.ref {
    margin-left: 3px;
  }
}

strong,
.bold {
  font-family: $font-bold;
  letter-spacing: 0.5px;
}
small,
.small {
  font-size: rem-calc(12);
}
.font-medium {
  font-family: $font-medium;
}

.lead-in {
  color: $primaryColor;
  font-size: rem-calc(18);
}

.error {
  font-size: rem-calc(10);
  color: $red;
  margin: 0;
  letter-spacing: rem-calc(0.5);
}

.footnote {
  position: relative;
  font-size: rem-calc(13);
  margin: 0;
  letter-spacing: 0.3px;
}

.hang {
  display: inline-block;
  text-indent: -5px;
}

.text-gradient {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(45deg, $grad-reverse);
  background-clip: text;
}

@include media-breakpoint-down(sm) {
  .mobile-break {
    display: block;
  }
}

.text-center {
  text-align: center;
}

.text-nowrap {
  white-space: nowrap;
}

.text-underline {
  text-decoration: underline;
}

.text-teal {
  color: $teal !important;
}

.text-grey {
  color: $body-color !important;
}

.text-blue {
  color: $blue !important;
}

.text-red {
  color: $red;
}

.text-green {
  color: $green;
}

.font-arial {
  font-family: Arial, Helvetica, sans-serif;
}

.font-book {
  font-family: $font-book !important;
}

.italic {
  font-style: italic;
}

.type-medium {
  font-size: rem-calc(24);
}

.type-title {
  font-size: rem-calc(28);
}

.type-large {
  font-size: rem-calc(36);
}
