/*!
 * Bootstrap v4.0.0-alpha.6 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "variables";
@import "mixins";

// Reset and dependencies
@import "normalize";
@import "print";

// Core CSS
@import "reboot";
@import "type";
@import "images";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";

// Components
@import "transitions";
@import "dropdown";
@import "input-group";
@import "nav";
@import "navbar";
@import "alert";
@import "progress";
@import "media";
@import "list-group";
@import "responsive-embed";
@import "close";

// Components w/ JavaScript
@import "modal";
@import "popover";

// Utility classes
@import "utilities";
