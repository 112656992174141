/////////////////
///// COLOR /////
/// /////////////////
$grey-1: #b7b7b7; // btn-outline outline, text input outline
$darkgrey: #4c4c4c; // text of btn-outline // body text
$lightgrey: #e5e5e5; // grey text
$grey-8: #f6f6f6; // button and tab bg

$teal: #0d9dad;

$blue: #05498c; // text and gradient-0
$darkblue: #1a2050; // background

$red: #e10000;

$white: #ffffff;
$white-opaque: rgba(255, 255, 255, 0.65);

$body-color: $darkgrey;
$primaryColor: $teal;
$primaryColorDrk: $blue;

$grad:
  $teal 45%,
  $blue 65%;
$grad-reverse:
  $teal 30%,
  $blue 70%;
$gradient-teal-blue: linear-gradient(90deg, $grad);
$gradient-blue-teal: linear-gradient(90deg, $blue 0%, $teal 80%);

////////////////////////
///// Font weights /////
////////////////////////
$font-light: "ApexNew-thin", Helvetica, Arial, sans-serif;
$font-book: "ApexNew", Helvetica, Arial, sans-serif;
$font-medium: "ApexNew-medium", Helvetica, Arial, sans-serif;
$font-bold: "ApexNew-bold", Helvetica, Arial, sans-serif;

///////////////////////
///// Measurments /////
///////////////////////
$topNavH: 30px; // top links
$mainNavH: 60px; // main navigation
$subnavH: 49px;
$bannerHeight: 72px;
$mainHeaderH: $mainNavH + $topNavH + $bannerHeight;

$mobileNavHeight: 89px;
$mobileBannerHeight: 52px;

$mobileFullHeaderHeight: $mobileNavHeight + $mobileBannerHeight;

//Scrolled
$scrollSubNavH: 30px;
$scrollMainNavH: 60px;
$scrollMainHeaderH: $scrollSubNavH + $scrollMainNavH + $bannerHeight;

$margin-lg: 68px;

$maxWidth: 1080px;
