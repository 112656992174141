$form-border: 1px solid $lightgrey;
$selection-width: 400px;
$form-color: #707070;
$inputHeight: rem-calc(40);

form {
  max-width: 730px;

  @include media-breakpoint-down(sm) {
    .form-columns-3 div {
      width: 100% !important;
    }
  }

  fieldset {
    max-width: 100% !important;
    margin-bottom: 1em !important;
  }

  a {
    text-decoration: underline;
  }

  ::marker {
    content: "";
  }

  .field {
    position: relative;
    padding: 0.5em 0;

    label {
      display: block;
      font-family: $font-bold;
      margin-bottom: 0.25rem;
    }

    input {
      background: none;
      border: $form-border;
      height: $inputHeight;
      padding: 0.4em;
      width: 100%;

      &:focus {
        outline: none;
        border: 1px solid $teal;
      }
    }

    select {
      color: $form-color;
      width: 100%;
      background: none;
      border: $form-border;
      padding: 0.4em;
      outline: none;
      appearance: none;
      height: $inputHeight;
      font-size: 1rem;
      background-image: linear-gradient(45deg, transparent 50%, $darkgrey 50%),
        linear-gradient(135deg, $darkgrey 50%, transparent 50%);
      background-position:
        calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px);
      background-size:
        5px 5px,
        5px 5px;
      background-repeat: no-repeat;

      &:focus {
        border: 1px solid #008484;
      }
    }

    ::placeholder {
      color: $form-color;
      font-size: 1rem;
    }
  }

  .inputs-list li {
    margin-top: 1.5em;

    label {
      position: relative;
      font-family: $font-book;
      padding-left: 3em;
      height: 2rem;
    }

    input {
      width: 2rem !important;
      height: 2rem;
      position: absolute;
      left: 0;
    }

    span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .hs-error-msgs li {
    margin-top: 0.5em;

    .hs-error-msg {
      font-size: rem-calc(12);
      color: $red;
      padding-left: 0;
    }
  }

  .hs-submit .hs-button {
    @extend .btn;
    @extend .btn-gradient-radius;

    padding-top: rem-calc(5);
    font-size: rem-calc(18);
    font-family: $font-bold;
  }
}
