.container {
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto;
  max-width: $maxWidth;
}

.link {
  text-decoration: underline;

  span {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 2px;
      left: 110%;
      width: 10px;
      height: 15px;
      background: transparent url("../images/arrow-right-small-blue.svg") no-repeat center 0;
    }
  }

  &:hover {
    text-decoration: none;
  }
}

article p {
  font-size: rem-calc(18);
}

@include media-breakpoint-up(md) {
  .md\:container {
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0 auto;
    max-width: $maxWidth;
  }
}
