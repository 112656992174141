#home {
  #page-content {
    background-image: linear-gradient(180deg, $grey-8 35%, $lightgrey 75%);
  }

  .jumbotron {
    position: relative;
    padding-top: rem-calc(50);
    padding-bottom: rem-calc(50);

    @include media-breakpoint-up(md) {
      padding-top: rem-calc(100);
      padding-bottom: rem-calc(100);
    }

    h1 {
      text-transform: uppercase;
      max-width: 800px;
      font-size: rem-calc(80);
    }

    .title {
      font-family: $font-medium;
      font-size: rem-calc(18);
      line-height: rem-calc(22);
      letter-spacing: 0.7px;
      margin: 25px 0;
      max-width: 590px;

      @include media-breakpoint-down(sm) {
        width: 223px;
        letter-spacing: 0;
      }
    }

    .cta {
      max-width: 640px;

      .btn {
        min-width: 200px;
      }
    }
  }

  #cards-container {
    padding-top: 15px;
    @include media-breakpoint-up(lg) {
      padding: 130px 0 50px;
    }

    h2 {
      text-align: center;
    }

    .card {
      background-color: #fff;
      border-top: 10px solid $primaryColorDrk;
      padding: 1em;
      margin: 0 auto;

      @include media-breakpoint-up(lg) {
        min-height: 500px;
      }

      .card-content {
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        width: 100%;

        div {
          display: flex;
          align-items: center;

          &.card-img {
            height: 45%;
            justify-content: center;

            img {
              width: 122px;
              height: auto;

              @include media-breakpoint-down(sm) {
                margin: 30px 0;
              }
            }
          }

          &.card-text {
            padding: 0;
            flex-direction: column;
            justify-content: space-between;
            text-align: center;
            padding-bottom: 30px;

            @include media-breakpoint-up(lg) {
              height: 55%;
            }

            .card-title {
              font-family: $font-bold;
              font-size: rem-calc(21);
              line-height: rem-calc(30);
              margin-top: 20px;
              margin-bottom: 0;

              @include media-breakpoint-up(sm) {
                font-size: rem-calc(24);
                line-height: rem-calc(28);
                margin-top: 0;
                margin-top: 20px;
              }
            }

            .card-description {
              margin-bottom: 10px;
            }

            .card-link {
              background-color: $primaryColorDrk;
              color: $white;
              border-radius: 50px;
              min-width: 100px;
              justify-content: center;
              padding: 1.2em 1.2em;
              transition: all 0.2s linear;
              font-family: $font-bold;
              font-size: rem-calc(14);
              text-align: center;

              @include media-breakpoint-down(sm) {
                margin: 0 auto;
              }

              &:hover {
                background-color: $grey-8;
                color: $primaryColorDrk;
              }
            }
          }
        }
      }
    }
  }

  #home-contact-us .contact-us-content {
    padding: 50px 0;

    @include media-breakpoint-up(md) {
      padding: 50px;
    }

    .pill {
      position: relative;

      .caption {
        position: absolute;
        left: rem-calc(50);
        bottom: rem-calc(30);

        @include media-breakpoint-up(md) {
          bottom: rem-calc(40);
        }
      }
    }

    .connect-us {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
}
