main#impact-of-comt {
  #moa-diagram-container {
    img {
      width: 100%;
    }
  }

  #mod {
    .icon-container {
      display: flex;
      justify-content: center;
    }
    .text-container {
      padding: 0;

      p {
        max-width: 700px;
        @include media-breakpoint-down(sm) {
          text-align: center;
          margin-top: 30px;
          padding: 0 15px;
        }
      }
    }
  }

  .video-container {
    align-items: center;

    img {
      width: 100%;
      margin-bottom: 2em;

      @include media-breakpoint-up(md) {
        padding-right: 2em;
        margin-bottom: 0;
      }

      &:hover {
        cursor: pointer;
      }
    }

    @include media-breakpoint-down(sm) {
      .video-modal-button {
        display: flex;
        justify-content: center;
      }
    }
  }
.moa-list-container.grid {

    column-gap: 0 !important;
}
  .moa-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid $lightgrey;
    padding: 0 1.5em;

    @include media-breakpoint-up(md) {
      border-bottom: none;
      border-right: 1px solid $lightgrey;

      &:last-of-type {
        border-right: none;
      }
    }

    img {
      max-width: 96px;
      padding: 30px 0;
    }
  }

  #molecule-img-container {
    @include media-breakpoint-up(md) {
      padding: 0 50px;
    }
  }
}
